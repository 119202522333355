<template>
  <div class="productDetail">
    <div style="background: no-repeat center center / cover" :style="{ backgroundImage: 'url(' + require('@/assets/breadcumb.jpg') + ')' }" class="breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="breadcumb-title" ref="title">{{ products.title }}</h2>
            <ul class="breadcumb-menu">
              <li><router-link to="/">{{ langContent.home }}</router-link></li>
              <li>{{ products.title }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="about-doctor-area ptb--120">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="doctor-thumb">
              <img :src="img" alt="blog-image">
            </div>
          </div>
          <div class="col-lg-7">
            <div class="doctor-info">
              <h4>{{ products.title }}</h4>
              <ul class="doctor-details">
                <li>
                  <p>{{ products.code }}</p>
                </li>
              </ul>
              <p>
                {{ products.desc}}
              </p>
            </div>
          </div>
          <div class="col-12" v-if="10<1">
            <div class="doctor-bio">
              <h2>Detay</h2>
              <div class="bio-text">
                <p>Gerekise tablo ve ekstra aciklama
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aut cumque, deleniti dolore dolorem dolorum enim et facere iusto molestias neque nobis nostrum porro quidem quo, sunt tempora ullam veritatis!</p>
              </div>
            </div>
          </div>
          </div>
        <div class="row mt-5">
            <div v-for="(item, index) in imgs" class="grid col-md-4"  @click="openLightboxOnSlide(index+1)">
              <div class="glry-item">
                <a class="">
                  <img :src="item" alt="gallery">
                </a>
              </div>
            </div>

        </div>

      </div>

      <img src="@/assets/vs1.png" alt="vector-shape1" class="vector-shape1">
    </div>
    <FsLightbox
        :toggler="toggler"
        :slide="slide"
        :sources="imgs"
    />
  </div>
</template>

<script>
import FsLightbox from "fslightbox-vue/v3";
import axiosCustom from "../../axiosCustom";

export default {
  components: { FsLightbox },
  data() {
    return {
      toggler: false,
      slide: 1,
      cat: [],
      products: [],
      imgs: [],
      img: [],
      langContent: [],
    }
  },
  created() {
    this.get()
  },
  methods: {
    get:function (){
      axiosCustom
          .get('langfix')
          .then(response => {
            this.langContent = response.data[0]
          })
          .catch(error => {
            console.log(error);
          })
      axiosCustom
          .get('products/' + this.$route.params.c)
          .then(response => {
            for (let key in response.data.img ) {
              if (key > 0){
                this.imgs.push(response.data.img[key])
              }else{
                this.img = response.data.img[key]
              }
            }
            this.products = response.data
            this.pageTitle = response.data.title
            document.title = 'Delta Plastik | '+this.pageTitle

          })
          .catch(error => {
            console.log(error);
          })
      axiosCustom
          .get('cats')
          .then(response => {
            this.cat = response.data.filter(c => c.id === this.products.cat);
            this.title()
          })
          .catch(error => {
            console.log(error);
          })
    },
    openLightboxOnSlide: function(number) {
      this.slide = number;
      this.toggler = !this.toggler;
    },
    title: function() {
      var title = this.products.title
      setTimeout(function (){
        console.log(title)
       document.title = 'Delta Plastik | '+title
     },4000)
    }
  }
}
</script>
